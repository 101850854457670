<template>
  <div class="row">
    <div col="12" class="mb-4">
      <profile-picture></profile-picture>
    </div>
    <div col="12" class="mb-4">
      <general></general>
    </div>
    <div col="12" class="mb-4">
      <professional-information></professional-information>
    </div>
    <div col="12" class="mb-4">
      <learning-interest></learning-interest>
    </div>
  </div>
</template>
<script>
import General from "./MyDetails/General.vue";
import ProfessionalInformation from "./MyDetails/ProfessionalInformation.vue";
import ProfilePicture from "./MyDetails/ProfilePicture.vue";
import LearningInterest from "./MyDetails/LearningInterest.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    General,
    ProfessionalInformation,
    LearningInterest,
    ProfilePicture
  },
  computed: {
    ...mapGetters(["allProfile"])
  },
  mounted() {
    if (Object.keys(this.allProfile).length === 0) {
      this.$store.dispatch("getProfile");
    }
  }
};
</script>
<style lang="scss" scoped>
.md-wrapper {
  display: flex;
  justify-content: space-between;
  .md-general,
  .md-professional-info,
  .md-learning-interest {
    width: 100%;
  }
}
</style>
