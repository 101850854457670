<template>
  <div class="md-learning-interest">
    <settings-card :section="getASection('learning_interest')">
      <template v-slot:header-text>
        <h5>
          {{ $t("settings.my_details.learning_interests.title") }}
          <img
            :src="require(`@/assets/images/settings/icons/information.svg`)"
            alt="Information"
            width="20"
            height="20"
            v-b-tooltip.hover
            :title="$t('settings.my_details.learning_interests.tooltip')"
            placement="bottom"
          />
        </h5>
      </template>
      <template v-slot:header-save__btn>
        <footer-button
          :section="getASection('learning_interest')"
          :disabled="hasLimitError"
        ></footer-button>
      </template>
      <template v-slot:card-content>
        <div>
          <ul class="md-list">
            <li>
              <label>{{ $t("onboarding.goals.hint") }}</label>
              <p>{{ allProfile.interests ? allProfile.interests : "-" }}</p>
            </li>
          </ul>
        </div>
      </template>
      <template v-slot:card-edit-content>
        <div class="md-learning--edit">
          <Textarea
            :label="$t('settings.my_details.learning_interests.label')"
            :placeholder="
              $t('settings.my_details.learning_interests.placeholder')
            "
            v-model="form.interests"
            :limit="160"
            @hasLimitError="e => (hasLimitError = e)"
          ></Textarea>
        </div>
      </template>
    </settings-card>
  </div>
</template>
<script>
import SettingsMixin from "../mixin";

import SettingsCard from "../Cards/SettingsCard.vue";
import FooterButton from "../Shared/FooterButton.vue";
export default {
  mixins: [SettingsMixin],
  components: { SettingsCard, FooterButton },
  data() {
    return {
      hasLimitError: false
    };
  }
};
</script>
<style lang="scss">
.md-learning--edit {
  height: calc(100% - 54px);
  .lxp-textarea {
    textarea {
      height: calc(100% - 44px);
    }
  }
}
.md-list {
  p {
    word-break: break-word;
  }
}
</style>
